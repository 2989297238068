<template>
  <div id="loading-wrap">
    <p>Loading...</p>
    <img src="@/assets/747.svg" />
  </div>
</template>

<script>
export default {
  computed: {
    status() {
      return this.$store.state.topLevelStatus;
    },
  },
};
</script>

<style lang="scss">
#loading-wrap {
  z-index: 2;
  position: fixed;
  top: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
