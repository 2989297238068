<template>
  <div
    class="bubble"
    v-if="person"
    :class="{ maybe: !approved }"
    @click="this.$router.push(`/photos/person/${person.id}`)"
  >
    <div class="person-image" :style="`background-image: url('${image}')`" />
    <div class="stacked">
      <p>{{ person.name }}{{ !approved ? "?" : "" }}</p>
      <small v-if="!person.username">{{ person.count }} photos</small>
      <small v-else>@{{ person.username }} | {{ person.count }} photos</small>
    </div>
  </div>
</template>

<script>
function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

import axios from "axios";
export default {
  props: ["person", "approved"],
  data: function () {
    return {
      image: "",
    };
  },
  async mounted() {
    if (this.person !== "unknown") {
      const image = await axios({
        responseType: "arraybuffer",
        url: `/api/photos/people/${this.person.id}/premier`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      this.image = `data:image/webp;base64,${_arrayBufferToBase64(image.data)}`;
      this.show = true;
    } else this.personObject = { type: "unknown", name: "Unknown" };
  },
};
</script>

<style lang="scss" scoped>
.bubble {
  padding: 6px;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  border-radius: 0.3em;
  gap: 6px;
  cursor: pointer;
  .person-image {
    height: 2em;
    width: 2em;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
  }
  .stacked {
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
      font-weight: 600;
    }
    small {
      margin: 0;

      font-size: 0.7em;
    }
  }
  &.maybe {
    opacity: 0.2;
    font-style: italic;
  }
}
</style>
