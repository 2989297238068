<template>
  <!-- <p>{{ user.id }} {{ user.name }}</p> -->
  <div v-if="user.id && !user.name">@{{ user.username }}</div>
  <div class="linkedPerson" v-else-if="user.id && user.name">
    <div
      class="image"
      :style="`background-image: url('/api/photosEmbedded/people/${user.lrid_global}/premier?token=${this.$store.state.token}')`"
    ></div>
    <div class="stacked">
      <p id="usersName">{{ user.name }}</p>
      <p id="username">@{{ user.username }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserControlsRight",
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="scss">
.linkedPerson {
  padding: 0.5em;
  background-color: #fff;
  color: #151518;

  display: flex;
  align-items: center;
  gap: 0.4em;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  div.image {
    height: 2em;
    width: 2em;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
  }
  div.stacked {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    #usersName {
      font-size: 1em;
      font-weight: 600;
    }
    #username {
      font-size: 0.8em;
    }
  }
}
</style>
