import { createStore } from "vuex";
import axios from "axios";
import router from "../router";

export const store = createStore({
  state: {
    topLevelStatus: "awaiting-password", //awaiting-password, error, photos, loading
    token: "",
    rbacToken: "",
    password: "",
    days: {},
    people: {},
    top10: {},
    albums: {},
    camcorder: {},
    metadata: {},
    currentlyShowing: {
      box: false,
      group: null,
      image: null,
    },
    user: {},
  },
  mutations: {
    updateTopLevelStatus(state, status) {
      state.topLevelStatus = status;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPassword(state, password) {
      state.password = password;
    },
    setPhotosAndPeople(state, object) {
      state.days = object.ph;
      state.people = object.pe;
      state.top10 = object.t1;
      state.albums = object.al;
      state.camcorder = object.cc;
      state.metadata = object.me;
    },
    setUser(state, user) {
      state.user = user;
    },
    rbacToken(state, token) {
      state.rbacToken = token;
    },
    setMetadata(state, metadata) {
      state.metadata = metadata;
    },
  },
  actions: {
    async updateMetadata({ commit, state }) {
      try {
        const metadata = await axios({
          url: `/api/photos/meta`,
          method: "GET",
          headers: { Authorization: "Bearer " + state.token },
        });
        commit("setMetadata", metadata.data);
      } catch (err) {
        console.err(err);
      }
    },
    async setToken({ commit }, password) {
      commit("updateTopLevelStatus", "loading");
      try {
        const token = await axios({
          url: `/api/token`,
          method: "GET",
          headers: { Authorization: "Bearer " + password },
        });
        commit("updateTopLevelStatus", "loading");
        const photos = await axios({
          url: `/api/photos/days`,
          method: "GET",
          headers: { Authorization: "Bearer " + token.data.token },
        });
        const people = await axios({
          url: `/api/photos/people`,
          method: "GET",
          headers: { Authorization: "Bearer " + token.data.token },
        });
        // const top10 = await axios({
        //   url: `/api/photos/top10`,
        //   method: "GET",
        //   headers: { Authorization: "Bearer " + token.data.token },
        // });
        const albums = await axios({
          url: `/api/photos/album`,
          method: "GET",
          headers: { Authorization: "Bearer " + token.data.token },
        });
        const camcorder = await axios({
          url: `/api/photos/camcorder/camcorder-init-load`,
          method: "GET",
          headers: { Authorization: "Bearer " + token.data.token },
        });
        const metadata = await axios({
          url: `/api/photos/meta`,
          method: "GET",
          headers: { Authorization: "Bearer " + token.data.token },
        });
        commit("setToken", token.data.token);
        commit("setPhotosAndPeople", {
          ph: photos.data,
          pe: people.data,
          // t1: top10.data,
          al: albums.data,
          cc: camcorder.data,
          me: metadata.data,
        });
        commit("updateTopLevelStatus", "photos");
        router.push("/photos");
      } catch (err) {
        commit("updateTopLevelStatus", "awaiting-password");
        alert("incorrect password");
      }
    },
    async signedInUser({ commit }, { token, user, rbacToken }) {
      commit("updateTopLevelStatus", "loading");
      commit("setToken", token);
      commit("setUser", user);
      commit("rbacToken", rbacToken);
      const photos = await axios({
        url: `/api/photos/days`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      const people = await axios({
        url: `/api/photos/people`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      // const top10 = await axios({
      //   url: `/api/photos/top10`,
      //   method: "GET",
      //   headers: { Authorization: "Bearer " + token },
      // });
      const albums = await axios({
        url: `/api/photos/album`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      const camcorder = await axios({
        url: `/api/photos/camcorder/camcorder-init-load`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      const metadata = await axios({
        url: `/api/photos/meta`,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      commit("setPhotosAndPeople", {
        ph: photos.data,
        pe: people.data,
        // t1: top10.data,
        al: albums.data,
        cc: camcorder.data,
        me: metadata.data,
      });
      commit("updateTopLevelStatus", "photos");
    },
  },
  getters: {
    personLrTag: (state) => (id) => {
      return state.people.find((tp) => tp.lrid.tag === id);
    },
    person: (state) => (id) => {
      return state.people.find((tp) => tp._id === id);
    },
    thisCamcorderDate: (state) => (year, day) => {
      return state.camcorder.allCamcorderEventsByDate.find(
        (cc) => cc._id === `${year}/${day}`
      );
    },
  },
});
