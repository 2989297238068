<template>
  <div class="photo-groups">
    <people-grid />
    <!-- <top-10-photos /> -->
    <recent-albums />
    <photo-group
      v-for="photoGroup of days"
      :key="photoGroup.date_grouping"
      :id="`photoGroup-${photoGroup.date_grouping}`"
      :photoGroup="photoGroup"
    />
  </div>
</template>

<script>
import PhotoGroup from "@/components/PhotoGroup";
import PeopleGrid from "@/components/PeopleGrid";
// import Top10Photos from "@/components/Top10Photos";
import RecentAlbums from "@/components/RecentAlbums.vue";
import _ from "lodash";

export default {
  components: {
    PhotoGroup,
    PeopleGrid,
    //  Top10Photos,
    RecentAlbums,
  },
  computed: {
    days() {
      return this.$store.state.days;
    },

    photosGrouped() {
      const photos = _(this.$store.state.photos)
        .groupBy((item) => item.datePhotographedGrouping)
        .value();
      return photos;
    },
  },
};
</script>

<style lang="scss">
h1 {
  text-align: left;
  padding: 0 1em;
}
</style>
