<template>
  <div class="album-block-outer" ref="people">
    <div class="album-block" v-if="status === 'success'">
      <div class="bubbles">
        <div
          class="album"
          v-for="album of albums"
          :key="album.album"
          @click="this.$router.push(`/photos/album/${album.album}`)"
        >
          <div
            class="background"
            :style="`background-image:
      url('/api/photosEmbedded/album/${album.album}/lscover?token=${this.$store.state.token}')`"
          >
            <div class="inner">
              <p>{{ album.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="album-block" v-else-if="status === 'pending'">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: function () {
    return {
      albums: [],
      observer: null,
      status: "pending",
    };
  },
  props: {
    photo: String,
  },
  methods: {
    async render() {
      const group = await axios({
        url: `/api/photos/photo/${this.photo}/albums`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      console.log(group.data);
      this.albums = group.data;
      this.status = "success";
    },
    intersectionChange(entry) {
      entry.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        this.render().then(() => {
          console.log("rendered");
        });
      });
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.intersectionChange, {
      root: this.$refs.image,
      rootMargin: "0px",
      threshold: 0.0,
    });
  },
  mounted() {
    this.observer.observe(this.$refs.people);
  },
};
</script>

<style lang="scss" scoped>
.bubbles {
  display: grid;
  gap: 0.4em;
}

.album {
  aspect-ratio: 1920 / 400;
  font-weight: 600;
  display: flex;
  cursor: pointer;

  .background {
    border-radius: 0.3em;

    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-size: cover;
    z-index: 1;
    .inner {
      border-radius: 0.3em;

      z-index: 2;
      backdrop-filter: brightness(0.2);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
