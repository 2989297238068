<template>
  <div class="people">
    <people-grid />

    <!-- <Teleport to="#photo-detail">
      <Transition>
        <photo-detail
          v-if="groups.liked.showLikedDetail"
          v-model:show-photo-detail="groups.liked.showLikedDetail"
          v-model:showing="groups.liked.showingLikedDetail"
          :showingGroupEndpoint="`/api/photos/people/${person._id.trim()}/group/liked`"
        />
      </Transition>
    </Teleport> -->

    <Teleport to="#photo-detail">
      <Transition>
        <photo-detail
          v-if="groups.photos.showPhotoDetail"
          v-model:show-photo-detail="groups.photos.showPhotoDetail"
          v-model:showing="groups.photos.showingPhotoDetail"
          :showingGroupEndpoint="`/api/photos/people/${person.id}/photos/confirmed`"
        />
      </Transition>
    </Teleport>

    <Teleport to="#photo-detail">
      <Transition>
        <photo-detail
          v-if="groups.unconfirmed.showPhotoDetail"
          v-model:show-photo-detail="groups.unconfirmed.showPhotoDetail"
          v-model:showing="groups.unconfirmed.showingPhotoDetail"
          :showingGroupEndpoint="`/api/photos/people/${person.id}/photos/unconfirmed`"
        />
      </Transition>
    </Teleport>

    <div class="top" v-if="(status = 'success')">
      <router-link to="/photos">Back to all photos</router-link>
      <h1>{{ person.name }}</h1>
      <!-- <span v-if="person.liked.length >= 1">
        <h2>Photos {{ person.name.replace(/ .*/, "") }} has liked</h2>
        <div class="photos photos-group">
          <photo-render-background-div
            v-for="(photo, index) in person.liked"
            class="photo-render"
            :key="photo"
            :imageUrl="`/api/photos/${photo}/thumb`"
            @click="showLikedDetail(index)"
          />
        </div>
      </span> -->
      <h2>Photos of {{ person.name_first }}</h2>
      <p>Confirmed in {{ person.count }} photos</p>
      <div class="photos photos-group">
        <photo-render-background-div
          v-for="(photo, index) in photosConfirmed"
          class="photo-render"
          :key="photo.id"
          :imageUrl="`/api/photos/${photo.id}/thumb`"
          @click="showPhotoDetail(index)"
        />
      </div>
      <span v-if="photosUnconfirmed.length > 0">
        <h2>Other potential photos of {{ person.name_first }}</h2>
        <div class="photos photos-group">
          <photo-render-background-div
            v-for="(photo, index) in photosUnconfirmed"
            class="photo-render"
            :key="photo.id"
            :imageUrl="`/api/photos/${photo.id}/thumb`"
            @click="showUnconfirmedDetail(index)"
          /></div
      ></span>
    </div>
  </div>
</template>

<script>
import PeopleGrid from "@/components/PeopleGrid";
import PhotoRenderBackgroundDiv from "@/components/PhotoRenderBackgroundDiv.vue";
import PhotoDetail from "@/components/PhotoDetail.vue";
import axios from "axios";

export default {
  name: "PersonView",
  components: { PeopleGrid, PhotoRenderBackgroundDiv, PhotoDetail },

  // computed: {
  //   person() {
  //     console.log(this.$route.params.person);
  //     console.log(this.$store.getters.person(this.$route.params.person));
  //     return this.$store.getters.person(this.$route.params.person);
  //   },
  //   confirmedPhotos() {
  //     console.log(this.person.photos);
  //     // return person.photos.filter((photo) => photo.confirmed);
  //     return this.person.photos;
  //   },
  // },

  data: function () {
    return {
      status: "pending",
      person: {},
      photosConfirmed: [],
      photosUnconfirmed: [],
      groups: {
        liked: {
          showLikedDetail: false,
          showingLikedDetail: 0,
        },
        photos: {
          showPhotoDetail: false,
          showingPhotoDetail: 0,
        },
        unconfirmed: {
          showPhotoDetail: false,
          showingPhotoDetail: 0,
        },
      },
    };
  },

  methods: {
    showLikedDetail(index) {
      console.log(index);
      this.groups.liked.showLikedDetail = true;
      this.groups.liked.showingLikedDetail = index;
    },
    showPhotoDetail(index) {
      console.log(index);
      this.groups.photos.showPhotoDetail = true;
      this.groups.photos.showingPhotoDetail = index;
    },
    showUnconfirmedDetail(index) {
      console.log(index);
      this.groups.unconfirmed.showPhotoDetail = true;
      this.groups.unconfirmed.showingPhotoDetail = index;
    },
    async update() {
      const personId = this.$route.params.person;
      const personMetadata = await axios({
        url: `/api/photos/people/${personId}/metadata`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      const photosConfirmed = await axios({
        url: `/api/photos/people/${personId}/photos/confirmed`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });
      const photosUnconfirmed = await axios({
        url: `/api/photos/people/${personId}/photos/unconfirmed`,
        method: "GET",
        headers: { Authorization: "Bearer " + this.$store.state.token },
      });

      await Promise.all([
        personMetadata,
        photosConfirmed,
        photosUnconfirmed,
      ]).then((values) => {
        this.person = values[0].data;
        this.photosConfirmed = values[1].data;
        this.photosUnconfirmed = values[2].data;
        this.status = "success";
      });
    },
  },
  async mounted() {
    await this.update();
  },
  watch: {
    $route: async function () {
      await this.update();
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  text-align: left;
  text-align: left;
  padding: 0 1em;
  h1 {
    padding: 0;
  }
  a {
    color: #fff;
  }
  .photos {
    display: flex;
    flex-direction: row;
    padding: 1em;
    flex-wrap: wrap;
    overflow: auto;
    gap: 1em;
    justify-content: center;
    .photo-render {
      height: 13rem;
      width: 13rem;
      min-width: 13rem;
      max-width: 13rem;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.1em;
        height: 100%;
        opacity: 0;
        width: 100%;
        transition: 0.2s ease-in-out;
        border: 2px solid transparent;
        &:hover {
          opacity: 1;
          transition: 0.2s ease-in-out;
          backdrop-filter: blur(2px) saturate(0.5) brightness(0.5);
          transform: scale(1.05);
          border: 2px solid #fff;
        }
        p {
          margin: 0px;
          &.name {
            font-weight: 600;
            padding: 0 0.3em;
          }
          &.photo-count {
            font-size: 0.7em;
          }
        }
      }
    }
  }
}
</style>
