<template>
  <div class="password-wrap">
    <div v-if="mode === `initial`" class="password form2">
      <h2>Sign in</h2>
      <input
        type="text"
        id="username"
        name="username"
        autocomplete="username"
        required
        autofocus
        v-model="username"
        placeholder="username"
        @keyup.enter="localLogin()"
      />
      <input
        type="password"
        id="password"
        name="password"
        autocomplete="password"
        required
        v-model="password"
        placeholder="password"
        @keyup.enter="localLogin()"
      />
      <button @click="localLogin">Login</button>
    </div>
    <div v-else-if="mode === `setGlobalJwtPassword`" class="password form2">
      <h2>What's the password?</h2>
      <input
        type="text"
        id="newpassword"
        name="newpassword"
        autocomplete="globalpassword"
        required
        autofocus
        v-model="globalPassword"
        placeholder="everybody's password"
        @keyup.enter="setGlobalJwtPasswordMethod"
      />
      <button @click="setGlobalJwtPasswordMethod">Login</button><br /><br />
      <sub>You'll be asked for this on a weekly basis.</sub>
    </div>
    <p>{{ errorMessages }}</p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SignInView",
  data: function () {
    return {
      mode: "initial",
      username: "",
      password: "",
      errorMessages: "",
      globalPassword: "",
      continuationToken: "",
      user: {},
    };
  },
  methods: {
    async localLogin() {
      this.errorMessages = "";
      const loginRequest = await axios({
        url: `/api/auth/local`,
        method: "post",
        credentials: true,
        data: {
          username: this.username,
          password: this.password,
        },
      });
      if (loginRequest.data.status === true) {
        if (loginRequest.data.jwtStatus === false) {
          this.mode = "setGlobalJwtPassword";
          this.user = loginRequest.data.user;
          this.continuationToken = loginRequest.data.continuationToken;
        } else if (loginRequest.data.jwtStatus === true) {
          this.$store.dispatch("signedInUser", {
            token: loginRequest.data.user.jwt,
            user: loginRequest.data.user,
            rbacToken: loginRequest.data.rbacToken,
          });
          this.$router.push("/photos");
        }
      } else {
        this.errorMessages = loginRequest.data.message;
      }
    },
    async setGlobalJwtPasswordMethod() {
      console.log(this.continuationToken);
      this.errorMessages = "";
      const globalLoginRequest = await axios({
        url: `/api/auth/continuation/setjwt`,
        method: "post",
        headers: {
          Authorization: `Bearer ${this.continuationToken}`,
        },
        data: {
          globalPassword: this.globalPassword,
        },
      });
      if (globalLoginRequest.data.status === true) {
        console.log(globalLoginRequest.data);
        this.$store.dispatch("signedInUser", {
          token: globalLoginRequest.data.token,
          user: this.user,
        });
        this.$router.push("/photos");
      } else {
        this.errorMessages = globalLoginRequest.data.message;
      }
    },
  },
};
</script>

<style lang="scss">
.password-wrap {
  flex-direction: column;
  align-items: center;
  .form2 {
    input {
      margin-bottom: 1em;
    }
    button {
      background-color: #fff;
      font-size: 18px;
      border-radius: 0.25em;
      border: 0px solid transparent;
      width: 100%;
      padding: 0.25em;
    }
  }
}
</style>
